<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      <v-col v-if="!mobile" cols="1"></v-col>
      <v-col v-bind:cols="mobile ? 6 : 3">
        <v-img
            :src="require('@/assets/picto/picto6.svg')"
            contain
            v-bind:height="mobile ? 100 : 150"
        />
        <v-row justify="center" v-bind:style="mobile ? 'padding-top: 30px' : 'margin-top: 30px'" no-gutters>
          <v-btn
              rounded
              v-bind:vlass="mobile ? 'btnRecommendationsMobile' : 'btnRecommendations'"
              v-bind:height="mobile ? '40px' : '65px'"
              v-bind:width="mobile ? '150px' : '200px'"
              dark
              href="https://www.anses.fr/fr/content/infographie-activit%C3%A9s-physiques-enfants-et-adolescents"
              target="_blank"
              v-html="$t('child')"
              color="#1579A7">
          </v-btn>
        </v-row>
      </v-col>
      <v-col v-bind:cols="mobile ? 6 : 3">
        <v-img
            :src="require('@/assets/picto/picto5.svg')"
            contain
            v-bind:height="mobile ? 100 : 150"
        />
        <v-row justify="center" v-bind:style="mobile ? 'padding-top: 30px' : 'margin-top: 30px'" no-gutters>
          <v-btn
              rounded
              v-bind:vlass="mobile ? 'btnRecommendationsMobile' : 'btnRecommendations'"
              v-bind:height="mobile ? '40px' : '65px'"
              v-bind:width="mobile ? '150px' : '200px'"
              dark
              href="https://www.anses.fr/fr/content/infographie-activit%C3%A9s-physiques-adultes"
              target="_blank"
              v-html="$t('adult')"
              color="#1579A7">
          </v-btn>
        </v-row>
      </v-col>
      <v-col v-bind:cols="mobile ? 12 : 3" v-bind:style="mobile ? 'padding-top: 70px' : ''">
        <v-img
            :src="require('@/assets/picto/picto4.svg')"
            contain
            v-bind:height="mobile ? 100 : 150"
        />
        <v-row justify="center" v-bind:style="mobile ? 'padding-top: 30px' : 'margin-top: 30px'" no-gutters>
          <v-btn
              rounded
              v-bind:vlass="mobile ? 'btnRecommendationsMobile' : 'btnRecommendations'"
              v-bind:height="mobile ? '40px' : '65px'"
              v-bind:width="mobile ? '150px' : '200px'"
              dark
              href="https://www.anses.fr/fr/content/infographie-activit%C3%A9s-physiques-personnes-de-plus-de-65-ans-0"
              target="_blank"
              v-html="$t('senior')"
              color="#1579A7">
          </v-btn>
        </v-row>
      </v-col>
      <v-col v-if="!mobile" cols="1"></v-col>
    </v-row>
    <div v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'">
      <v-row justify="center" no-gutters>
        <h1 v-bind:class="mobile ? 'titleSectionMobile' : 'titleSection'">{{ $t('secondTitle') }}</h1>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-img
            v-if="$i18n.locale === 'fr'"
            class="btnSecondSection"
            :src="require('@/assets/recommendationsPhysical/discoverFr.png')"
            contain
            v-bind:max-width="mobile ? 175 : 300"
            height="200"
            @click="$router.push({name: 'PhysicalActivities'}).then(() => {$vuetify.goTo(0)})"
        />
        <v-img
            v-else
            class="btnSecondSection"
            :src="require('@/assets/recommendationsPhysical/discoverEn.png')"
            contain
            v-bind:max-width="mobile ? 175 : 300"
            height="200"
            @click="$router.push({name: 'PhysicalActivities'}).then(() => {$vuetify.goTo(0)})"
        />
      </v-row>
      <br v-if="mobile">
      <br v-if="mobile">
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecommendationsPhysicalComp',
  props: ['mobile'],
  data: () => ({}),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Recommendations in physical activity",
    "secondTitle": "Calculate my level of physical activity",
    "child": "Child",
    "adult": "Adult",
    "senior": "Senior"
  },
  "fr": {
    "mainTitle": "Les recommandations en activité physique",
    "secondTitle": "Les activités physiques adaptées proposées par l'association",
    "child": "Enfant",
    "adult": "Adulte",
    "senior": "Sénior"
  }
}
</i18n>

<style scoped>

.titleSection {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #FFFFFF;
  font-family: Comfortaa, sans-serif;
}

.titleSectionMobile {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #FFFFFF;
  font-family: Comfortaa, sans-serif;
}

.firstSection {
  padding-top: 40px;
  padding-bottom: 100px;
}

.firstSectionMobile {
  padding-bottom: 70px;
}

.secondSection {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #1579A7;
}

.secondSectionMobile {
  padding-top:  30px;
  background-color: #1579A7;
}

.btnSecondSection {
  cursor: pointer;
  margin-top: 40px;
}

.btnRecommendations {
  text-transform: none;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
}

.btnRecommendationsMobile {
  text-transform: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
}

</style>
