<template>
  <div>
    <RecommendationsPhysicalComp v-bind:mobile="mobile" />
  </div>
</template>

<script>
  import RecommendationsPhysicalComp from '@/components/desktop/WantPractice/RecommendationsPhysicalComp.vue'

  export default {
    name: 'RecommendationsPhysical',
    props: ['mobile'],
    components: {
      RecommendationsPhysicalComp
    },
  }
</script>
